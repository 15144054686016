<template>
    <Layout  style="background-color: #e9ecef;height: 1000px">
        <PageHeader :title="title" />
        <hr style="margin-top: -1rem;height: 1px;"/>
        <div style="background-color: white;min-height: 200px;max-height: 800px;overflow:auto">
            <div class="col-md-12" style="text-align:center"  >
                <b-button style="margin-top:20px"  variant="primary" @click="addCatlog">新建分类</b-button>
            </div>
            <div class="col-12">
                <!-- Table -->
                <div class="col-lg-12" style="margin-top: 20px">
                    <div class="card">
                        <div class="card-body">

                            <div class="table-responsive">
                                <table class="table table-striped mb-0">
                                    <thead>
                                    <tr>
                                        <th colspan="1">#</th>
                                        <th colspan="2">分类名称</th>
                                        <th colspan="1">博客数量</th>
                                        <th colspan="0">操作</th>
                                    </tr>
                                    </thead>
                                    <tbody v-for="(col,index) in typeList" v-bind:key="col.id">
                                    <tr aria-colspan="12">
                                        <td scope="row" colspan="1">
                                            {{index + 1}}
                                        </td>
                                        <td colspan="2">{{col.typeName}}</td>
                                        <td colspan="1">{{col.blogCount}}</td>
                                        <td colspan="0">
                                            <i class="ri-edit-line" style="color: #3a8ee6;margin-right: 5px" @click="editCatlog(col)"></i>
                                            <i class="ri-delete-bin-6-line" style="color: red" @click="removeCatlog(col)"></i>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <!--新建弹窗-->
                <div class="col-sm-12 col-md-4 col-xl-10">
                    <div class="my-12 text-center">
                        <b-modal
                                size="lg"
                                id="modal-scrollable"
                                scrollable
                                :title="modalTitle"
                                title-class="font-18" hide-footer>
                            <div class="col-12">
                                <form class="form-horizontal" role="form" @submit.prevent="catlogForm" @submit="onSubmit" @reset="onReset">
                                    <b-form-group
                                            label-for="text"
                                            label-cols-lg="2"
                                            label="分类名称" >
                                        <input
                                                id="validationTooltip03"
                                                v-model="form.typeName"
                                                type="text"
                                                class="form-control"
                                                :class="{ 'is-invalid': submitform && $v.form.typeName.$error }"
                                        />
                                        <div v-if="submitform && $v.form.typeName.$error" class="invalid-feedback">
                                            <span v-if="!$v.form.typeName.required">请填写分类名称</span>
                                        </div>
                                    </b-form-group>
                                    <div style="margin-top: 20px;float: right;padding: 20px 20px">

                                        <b-button class="btn btn-default" style="background-color: gray;color: white;margin-right: 10px" type="reset">取消</b-button>
                                        <b-button  variant="primary" type="submit">保存</b-button>
                                    </div>
                                </form>
                            </div>
                        </b-modal>
                    </div>
                </div>

            </div>
        </div>
    </Layout>
</template>
<script>
    import Layout from "../../layouts/main";
    import PageHeader from "@/components/page-header";
    import appConfig from "@/app.config";
    import Swal from "sweetalert2";
    import {required} from "vuelidate/lib/validators";

    /**
     * Advanced table component
     */
    export default {
        page: {
            title: "分类管理",
            meta: [{ name: "description", content: appConfig.description }]
        },
        components: { Layout, PageHeader },
        data() {
            return {
                detailId:'',
                typeList: [],
                showDetail:false,
                title: "分类管理",
                totalRows: 1,
                currentPage: 1,
                perPage: 10,
                pageOptions: [10, 25, 50, 100],
                filter: null,
                filterOn: [],
                sortBy: "age",
                sortDesc: false,
                modalTitle: '新建分类',
                submitform: false,
                form: {
                    typeName:'',
                    id:''
                },
            };
        },
        validations: {
            form: {
                typeName: { required }
            },

        },
        computed: {
            /**
             * Total no. of records
             */
            rows() {
                return this.typeList.length;
            }
        },
        mounted() {
            // Set the initial number of items
            this.totalRows = this.typeList.length;
        },
        methods: {
            /**
             * Search the table data with search input
             */
            onFiltered(filteredItems) {
                // Trigger pagination to update the number of buttons/pages due to filtering
                this.totalRows = filteredItems.length;
                this.currentPage = 1;
            },
            removeCatlog(col) {
                console.log(col)
                Swal.fire({
                    text: "此操作将永久删除该分类, 是否继续!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#34c38f",
                    cancelButtonColor: "#f46a6a",
                    confirmButtonText: "确定",
                    cancelButtonText: "取消"
                }).then(result => {
                    if (result.value) {
                        if (this.deleteType(col.id)) {
                            Swal.fire("Deleted!", "Your file has been deleted.", "success");
                        }
                    }
                });
            },
            addCatlog(){
                this.modalTitle = "新建分类";
                this.$bvModal.show("modal-scrollable")
            },
            editCatlog(col){
                console.log(col)
                this.modalTitle = "编辑分类";
                this.form.typeName = col.typeName;
                this.form.id = col.id;
                this.$bvModal.show("modal-scrollable")
            },
            async onSubmit(evt) {
                evt.preventDefault()
                if (this.$v.$invalid) {
                    return;
                } else {
                    let url = '/type/insert';
                    if (this.form.id != '') {
                        url = '/type/update';
                    }
                    const {data: res} = await this.$blog.post(url,this.form )
                    if (res.code !== 200) {
                        this.makeToast('danger',res.msg)
                        return null
                    } else  {
                        this.resetForm();
                        this.makeToast('success',res.msg)
                        this.$bvModal.hide("modal-scrollable");
                        this.submitform = false;
                        this.getFullTypeList();
                        return res.data
                    }

                }

            },

            onReset(evt) {
                evt.preventDefault()
                // Reset our form values
                this.resetForm();
                this.$bvModal.hide("modal-scrollable");
                this.submitform = false;
            },

            catlogForm() {
                this.submitform = true;
                this.$v.$touch();
            },
            // 得到所有的分类
            async getFullTypeList() {
                const {data: res} = await this.$blog.get('/type/getall')
                this.typeList = res.data
            },
            async deleteType (id){
                const {data: res} = await this.$blog.post('/type/delete/'+id)
                if (res.code == '200') {
                    this.getFullTypeList();
                    return true;
                }else{
                    return  false;
                }

            },
            makeToast(variant = null,msg = null) {
                this.$bvToast.toast(msg, {
                    title: variant,
                    variant: variant,
                    solid: true
                });
            },
            resetForm(){
                this.form = {
                    typeName: '',
                    id:'',
                }
            }

        },
        created() {
            this.getFullTypeList()
        },
    };
</script>

<style>

</style>